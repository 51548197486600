<template>
  <div
    class="w-100 h-100 p-3 position-relative overflow-auto bg-white shadow-sm d-flex align-items-start flex-column"
  >
    <div class="close-button pr-3 d-flex justify-content-end position-absolute">
      <b-button variant="link" class="close p-3" @click="$emit('close')">
        <span> &times; </span>
      </b-button>
    </div>
    <div v-if="isLoading" class="w-100 d-flex justify-content-center py-5">
      <custom-loading />
    </div>
    <div v-else class="d-flex align-items-start flex-column w-100 p-3">
      <span class="pt-2 break-word">
        <b>Data Operação</b>: {{ loadData("dataOperacao") }}
      </span>
      <span class="pt-2 break-word"> <b>DTA</b>: {{ loadData("dta") }} </span>
      <span class="pt-2 break-word">
        <b>Empresa</b>: {{ loadData("empresa") }}
      </span>
      <span class="pt-2 break-word">
        <b>Nro Contêiner/Placa</b>: {{ loadData("container") }}
      </span>
      <span class="pt-2 break-word">
        <b>Local de Origem</b>: {{ loadData("localOrigem") }}
      </span>
      <span class="pt-2 break-word">
        <b>Placa Trator</b>: {{ loadData("placaTrator") }}
      </span>
      <span class="pt-2 break-word">
        <b>Placa Reboque</b>: {{ loadData("placaReboque") }}
      </span>
      <span class="pt-2 break-word">
        <b>Observações</b>: {{ loadData("observacoes") }}
      </span>
      <span class="pt-2 break-word">
        <b>Local Destino</b>: {{ loadData("localDestino") }}
      </span>
      <span class="pt-2 break-word">
        <b>Operador Portuário</b>: {{ loadData("operador") }}
      </span>
      <span class="pt-2 break-word">
        <b>Operação</b>: {{ loadData("acao") }}
      </span>
    </div>
  </div>
</template>

<script>
import { OperacaoPortuariaService } from "@/services";

export default {
  components: {
    CustomLoading: () => import("@/components/Loading"),
  },
  props: {
    selectedItem: {
      type: Object,
    },
  },
  data() {
    return {
      loaded: null,
      isLoading: false,
    };
  },
  watch: {
    selectedItem(value) {
      if (value) this.fetchData();
    },
  },
  methods: {
    loadData(key) {
      return this.loaded ? this.loaded[key] : "";
    },
    fetchData() {
      this.isLoading = true;
      OperacaoPortuariaService.buscarPorId({
        movimentacaoId: this.selectedItem.id,
      })
        .then((resposta) => {
          this.loaded = {
            dta: resposta.data.dta,
            dataOperacao: resposta.data.dataOperacao,
            container: resposta.data.containers.numero,
            empresa: resposta.data.movimentacao.empresa.razaoSocial,
            localOrigem: resposta.data.armlocalOrigem.descricao,
            placaTrator: resposta.data.placaTrator,
            placaReboque: resposta.data.placaReboque,
            localDestino: resposta.data.armlocalDestino.descricao,
            operador: resposta.data.empresaOperador.razaoSocial,
            observacoes: resposta.data.observacoes,
            acao: resposta.data.acoes.descricao,
          };
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  min-height: 46px;
}
.close-button {
  right: 0;
}
</style>
