<template>
  <b-table
    ref="selectableTable"
    class="custom-table bg-white"
    :no-select-on-click="$props['clickable']"
    @row-selected="onRowSelected"
    @row-clicked="onRowClicked"
    v-bind="$attrs"
    v-on="$listeners"
    :slots="$slots"
    :labelSortDesc="labelSortDesc"
    :labelSortAsc="labelSortAsc"
    show-empty
  >
    <template v-slot:empty>
      <h4 class="py-4 text-center">{{ emptyText }}</h4>
    </template>
    <template v-slot:table-busy>
      <div class="w-100 h-100 d-flex justify-content-center py-5">
        <custom-loading />
      </div>
    </template>
    <template v-slot:head()="data">
      <span class="sorting">{{ data.label }}</span>
    </template>
    <template v-slot:head(check)="" v-if="checkSelectable()">
      <b-checkbox @change="toggleAll" />
    </template>
    <template v-slot:cell(check)="data" v-if="checkSelectable()">
      <b-checkbox
        v-model="data.rowSelected"
        @change="
          (checked) =>
            checked
              ? $refs.selectableTable.selectRow(data.index)
              : $refs.selectableTable.unselectRow(data.index)
        "
      />
    </template>
    <template
      v-for="(_, slot) in $scopedSlots"
      v-slot:[`cell(${slot})`]="scope"
    >
      <slot :name="slot" v-bind="scope" />
    </template>
  </b-table>
</template>

<script>
export default {
  name: "custom-table",
  components: {
    CustomLoading: () => import("@/components/Loading"),
  },
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    labelSortDesc: {
      type: String,
      default: "",
    },
    labelSortAsc: {
      type: String,
      default: "",
    },
    emptyText: {
      type: String,
      default: "Sua lista está vazia",
    },
  },
  inheritAttrs: false,
  methods: {
    toggleAll(checked) {
      checked ? this.selectAllRows() : this.clearSelected();
    },
    onRowSelected(items) {
      this.$emit("selectedItems", items);
    },
    onRowClicked(item) {
      if (!this.clickable) return;
      this.$attrs["items"].map(
        (i) =>
          (i._rowVariant =
            i === item && i._rowVariant === "" ? "info-light" : "")
      );
      this.$emit("clickedItem", item);
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    checkSelectable() {
      return (
        typeof this.$attrs["selectable"] !== "undefined" ||
        (this.$attrs["selectable"] !== false &&
          this.$attrs["select-mode"] !== "single")
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-table {
  ::v-deep .b-table-row-selected:focus {
    outline: none !important;
  }
  ::v-deep .table tr,
  ::v-deep .table th {
    outline: none !important;
  }
}
</style>
