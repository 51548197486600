class ToastManager {
  constructor() { }

  get componentVue() {
    return this._componentVue;
  }

  set componentVue(comp) {
    this._componentVue = comp;
  }

  configure(comp) {
    this._componentVue = comp;
  }

  successMessage(text) {
    const vNodesMsg = this.componentVue.$createElement('span', {
      class: 'break-word',
      domProps: {
        innerHTML: text,
      },
    });

    this.componentVue.$bvToast.toast([vNodesMsg], {
      title: 'Mensagem do Sistema',
      variant: 'success',
      solid: true,
    });
  }

  dangerMessage(text) {
    const vNodesMsg = this.componentVue.$createElement('span', {
      class: 'break-word',
      domProps: {
        innerHTML: text,
      },
    });

    this.componentVue.$bvToast.toast([vNodesMsg], {
      title: 'Mensagem do Sistema',
      variant: 'danger',
      solid: true,
    });
  }

  warningMessage(text, options = {}) {
    const vNodesMsg = this.componentVue.$createElement('span', {
      class: 'break-word',
      domProps: {
        innerHTML: text,
      },
    });

    this.componentVue.$bvToast.toast(vNodesMsg, {
      title: 'Mensagem do Sistema',
      variant: 'warning',
      solid: true,
      ...options,
    });
  }
}

export default new ToastManager();
