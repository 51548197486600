<template>
  <div class="user-container d-grid p-3 w-100 h-100">
    <div class="d-flex justify-content-between mb-3">
      <div class="position-relative" v-if="!isLoading">
        <b-button
          id="filter-button"
          class="custom-button rounded-circle"
          @click="filterSidebarOpened = !filterSidebarOpened"
          v-b-tooltip.hover.right="'Filtrar teu'"
        >
          <b-icon-filter />
        </b-button>
        <b-icon-trash
          v-if="isFiltered"
          @click="clearFilters"
          class="clear-filters rounded-circle bg-danger text-white position-absolute"
          :scale="0.6"
          v-b-tooltip.hover.right="'Limpar filtros'"
        />
      </div>
      <div
        class="screen-title w-100 d-flex align-items-center justify-content-center"
      >
        <h4 class="mb-0">Lista de Teus</h4>
      </div>
      <b-button
        v-if="!isLoading"
        id="create-user-button"
        class="custom-button d-flex align-items-center justify-content-center "
        :to="{ name: 'TeusAdicionar' }"
        v-b-tooltip.hover.left="'Criar Novo Teu'"
        :disabled="papelUsuario == 'ROLE_RECEITA'"
      >
        Inserir
      </b-button>
    </div>
    <div class="row flex-nowrap flex-fill overflow-hidden">
      <div :class="[`col-${tableSidebarOpened ? '9' : '12'}`]">
        <div class="flex-fill d-flex flex-column">
          <CustomTable
            ref="userTable"
            hover
            responsive
            selectable
            outlined
            :busy="isLoading"
            select-mode="single"
            no-local-sorting
            :fields="fields"
            :items="items"
            @sort-changed="filterFunction"
            emptyText="Nenhum Registro Encontrado"
          >
            <template slot="actions" slot-scope="row">
              <section class="text-right">
                <b-button
                  variant="link"
                  class="pl-2"
                  @click.prevent="editUser(row)"
                  :disabled="papelUsuario == 'ROLE_RECEITA'"
                >
                  <b-icon
                    variant="secondary"
                    icon="pencil"
                    v-b-tooltip.hover.bottom="'Editar teu'"
                  />
                </b-button>
                <b-button
                  variant="link"
                  v-b-modal.delete-modal
                  @click.prevent="deleteUser(row)"
                  :disabled="papelUsuario == 'ROLE_RECEITA'"
                >
                  <b-icon
                    variant="danger"
                    icon="trash"
                    v-b-tooltip.hover.bottom="'Remover teu'"
                  />
                </b-button>
              </section>
            </template>
          </CustomTable>
          <CustomPagination
            v-if="!isLoading"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="pageSize"
            @input="fetchData"
          >
            <template slot="select-option">
              <b-form-select
                v-model="pageSize"
                :options="options"
                @input="fetchData"
                value-field="item"
                text-field="name"
                disabled-field="notEnabled"
              />
            </template>
          </CustomPagination>
        </div>
      </div>
      <div
        :class="[`${tableSidebarOpened ? 'd-block' : 'd-none'}`]"
        class="col-3 pl-0"
      >
        <transition name="fade" mode="out-in">
          <Details
            v-show="tableSidebarOpened"
            :selectedItem="selectedItem"
            @close="closeSidebar"
            @delete-user="openConfirmModal"
            @edit-user="editUser"
          />
        </transition>
      </div>
    </div>
    <ConfirmModal
      id="delete-modal"
      icon="trash"
      variant="danger"
      verificationText="remover"
      :customTitle="`Deseja realmente deletar a teu <i>${itemToDelete.descricao}</i>?`"
      description="Se esta teu for deletada toda a informação será perdida."
      ok-title="Confirmar"
      cancel-title="Cancelar"
      @ok="onDelete"
    />
    <b-sidebar
      v-model="filterSidebarOpened"
      title="Filtrar Teus"
      backdrop-variant="dark"
      backdrop
      shadow
    >
      <TeuFilter @filtered="filterPage" ref="teuFilter" />
    </b-sidebar>
  </div>
</template>

<script>
import ToastHelper from "../../helpers/toastHelper";
import CustomTable from "../../components/Table";
import ConfirmModal from "../../components/ConfirmModal";
import Details from "./components/Details";
import TeuFilter from "./components/TeuFilter";
import { TeuService } from "../../services/teuService";
import UsuarioHelper from "@/helpers/usuarioHelper";
import { UsuarioService } from "@/services";

export default {
  components: {
    ConfirmModal,
    CustomPagination: () => import("@/components/Pagination"),
    CustomTable,
    Details,
    TeuFilter,
  },
  computed: {
    rows() {
      return this.items.length;
    },
    fields() {
      return [
        {
          key: "descricao",
          label: "Descrição",
          sortable: true,
          tdClass: "align-middle",
        },
        {
          key: "actions",
          label: "",
          tdClass: "align-middle",
        },
      ];
    },
  },
  data() {
    return {
      papelUsuario: "",
      currentPage: 1,
      filterSidebarOpened: false,
      filterData: null,
      isFiltered: false,
      isLoading: false,
      items: [],
      orderBy: null,
      options: [
        { item: 15, value: 15, name: "15 Registros" },
        { item: 30, value: 30, name: "30 Registros" },
        { item: 60, value: 60, name: "60 Registros" },
        { item: 90, value: 90, name: "90 Registros" },
      ],
      pageSize: 15,
      selectedItem: null,
      sortBy: null,
      tableSidebarOpened: false,
      totalRows: 0,
      itemToDelete: {},
    };
  },
  methods: {
    clearFilters() {
      this.filterData = null;
      this.fetchData();
      this.$refs.teuFilter.clearForm();
    },
    clearSelected() {
      this.selectedItem = null;
    },
    closeSidebar() {
      this.tableSidebarOpened = false;
      this.clearSelected();
    },
    deleteUser(marcadoria) {
      this.itemToDelete = marcadoria.item;
      this.closeSidebar();
    },
    editUser(marcadoria) {
      this.closeSidebar();
      this.$router.push({
        name: "TeusEditar",
        params: { id: marcadoria.item.id },
      });
    },
    fetchData() {
      this.closeSidebar();
      this.isLoading = true;
      TeuService.get({
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        sortBy: this.sortBy,
        orderBy: this.orderBy,
        ...this.filterData,
      })
        .then(({ data }) => {
          this.isFiltered = !!this.filterData;
          this.items = data.content;
          this.totalRows = data.totalElements;
          this.pageSize = data.size;
          if (this.totalRows == 0) {
            ToastHelper.warningMessage(`Nenhum Registro Encontrado`);
          }
        })
        .catch(() =>
          ToastHelper.dangerMessage(
            "Houve algum erro ao carregar dados das marcadorias"
          )
        )
        .finally(() => (this.isLoading = false));
    },
    filterFunction(row) {
      this.sortBy = row.sortBy;
      this.orderBy = row.sortDesc ? "desc" : "asc";
      this.fetchData();
    },
    filterPage({ descricao }) {
      this.filterSidebarOpened = false;
      this.filterData = null;
      if (descricao) {
        this.filterData = { descricao };
      }
      this.resetData();
      this.fetchData();
    },
    onDelete() {
      this.isLoading = true;
      TeuService.excluirPorId({ id: this.itemToDelete.id })
        .then(() => {
          let mensagem = `${this.itemToDelete.descricao} excluido com sucesso.`;
          this.fetchData();
          ToastHelper.successMessage(mensagem);
        })
        .catch(() =>
          ToastHelper.dangerMessage("Ocorreu algum erro ao tentar remover teu.")
        )
        .finally(() => {
          this.tableSidebarOpened = false;
          this.clearSelected();
        });
    },
    openConfirmModal() {
      this.itemToDelete = this.selectedItem;
      this.$bvModal.show("delete-modal");
    },
    resetData() {
      const { currentPage } = this.$options.data.call(this);
      this.currentPage = currentPage;
    },
    selectedItems(items) {
      this.selectedItem = items[0];
      this.tableSidebarOpened = !!items.length;
    },
  },
  created() {
    this.fetchData();
    UsuarioService.getCurrentUser().then((resposta) => {
      this.papelUsuario = UsuarioHelper.getPapelUsuario(resposta.data);
    });
  },
};
</script>
<style lang="scss" scoped>
.clear-filters {
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.user-container {
  grid-template-rows: minmax(auto, auto) 1fr;
}
.screen-title {
  min-height: 45px;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.custom-button {
  min-height: 45px;
  min-width: 45px;
  background-color: $bahama-blue;
}
</style>
