const UsuarioHelper = {
  getPapelUsuario(usuarioAtual) {
    for (var i = 0; i < usuarioAtual.authorities.length; i++) {
      let papel = usuarioAtual.authorities[i];
      if (papel === "ROLE_DOCAS" || papel === "ROLE_RECEITA") {
        return papel;
      }
    }
  }
};

export default UsuarioHelper;
