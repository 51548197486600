export const TipoContainer = Object.freeze({
    CONTAINER: { sigla: 'C', descricao: 'Contêiner' },
    PLACA: { sigla: 'P', descricao: 'Placa' }
});

export const RelatorioMovimentacaoTipoAgrupamentoEnum = Object.freeze({
    DTA: { id: 1, descricao: 'DTA' },
    EMPRESA: { id: 2, descricao: 'Empresa' },
    MERCADORIA: { id: 3, descricao: 'Mercadoria' },
    DUE: { id: 4, descricao: 'DU-E' },
    DECIMPORTACAO: { id: 5, descricao: 'Declaração de Importação' }
});