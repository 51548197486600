import { CustomAxios } from './axios';
import { oidcSettings } from "@/config/oidc.js";

export const UsuarioService = {
  getTotalUsers: ({ nome, sobrenome, email }) => {
    let params = "";
    if (nome) {
      params += `&firstName=${nome}`;
    }
    if (sobrenome) {
      params += `&lastName=${sobrenome}`;
    }
    if (email) {
      params += `&email=${email}`;
    }
    return CustomAxios.get(oidcSettings.adminAuthority + `users/count?1=1${params}`);
  },
  getUsers: ({ currentPage, pageSize, nome, sobrenome, email }) => {
    currentPage = currentPage - 1;
    let params = "";
    if (nome) {
      params += `&firstName=${nome}`;
    }
    if (sobrenome) {
      params += `&lastName=${sobrenome}`;
    }
    if (email) {
      params += `&email=${email}`;
    }
    console.log(oidcSettings.adminAuthority + `users?first=${currentPage}&max=${pageSize}${params}`);
    return CustomAxios.get(oidcSettings.adminAuthority + `users?first=${currentPage}&max=${pageSize}${params}`);
  },
  findById: (id) => {
    return CustomAxios.get(oidcSettings.adminAuthority + "users/" + id + "/role-mappings/realm?first=0&max=15");
  },
  getCurrentUser: () => {
    return CustomAxios.get(oidcSettings.authority + "protocol/openid-connect/userinfo");
  },
};
