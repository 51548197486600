<template>
  <div class="user-container d-grid p-3 w-100 h-100">
    <div class="d-flex justify-content-between mb-3">
      <div class="position-relative" v-if="!isLoading">
        <b-button
          id="filter-button"
          class="custom-button rounded-circle"
          @click="filterSidebarOpened = !filterSidebarOpened"
          v-b-tooltip.hover.right="'Filtrar Usuários'"
        >
          <b-icon-filter />
        </b-button>
        <b-icon-trash
          v-if="isFiltered"
          @click="clearFilters"
          class="clear-filters rounded-circle bg-danger text-white position-absolute"
          :scale="0.6"
          v-b-tooltip.hover.right="'Limpar filtros'"
        />
      </div>
      <div
        class="screen-title w-100 d-flex align-items-center justify-content-center"
      >
        <h4 class="mb-0">Lista de Usuários</h4>
      </div>
    </div>
    <div class="row flex-nowrap flex-fill overflow-hidden">
      <div :class="[`col-${tableSidebarOpened ? '9' : '12'}`]">
        <div class="flex-fill d-flex flex-column">
          <CustomTable
            ref="userTable"
            hover
            responsive
            selectable
            outlined
            :busy="isLoading"
            select-mode="single"
            no-local-sorting
            :fields="fields"
            :items="items"
            @sort-changed="filterFunction"
            emptyText="Nenhum Registro Encontrado"
          >
          </CustomTable>
          <CustomPagination
            v-if="!isLoading"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="pageSize"
            @input="fetchData"
          >
          </CustomPagination>
        </div>
      </div>
    </div>
    <b-sidebar
      v-model="filterSidebarOpened"
      title="Filtrar Usuários"
      backdrop-variant="dark"
      backdrop
      shadow
    >
      <UsuarioFilter @filtered="filterPage" ref="usuarioFilter" />
    </b-sidebar>
  </div>
</template>

<script>
import ToastHelper from "../../helpers/toastHelper";
import CustomTable from "../../components/Table";
import UsuarioFilter from "./components/UsuarioFilter";
import UsuarioHelper from "@/helpers/usuarioHelper";
import { UsuarioService } from "@/services";

export default {
  components: {
    CustomPagination: () => import("@/components/Pagination"),
    CustomTable,
    UsuarioFilter,
  },
  computed: {
    rows() {
      return this.items.length;
    },
    fields() {
      return [
        {
          key: "firstName",
          label: "Nome",
          tdClass: "align-middle",
        },
        {
          key: "lastName",
          label: "Sobrenome",
          tdClass: "align-middle",
        },
        {
          key: "email",
          label: "E-mail",
          tdClass: "align-middle",
        },
        {
          key: "perfil",
          label: "Perfil",
          tdClass: "align-middle",
        },
        {
          key: "actions",
          label: "",
          tdClass: "align-middle",
        },
      ];
    },
  },
  data() {
    return {
      papelUsuario: "",
      currentPage: 1,
      filterSidebarOpened: false,
      filterData: null,
      isFiltered: false,
      isLoading: false,
      items: [],
      orderBy: null,
      pageSize: 15,
      selectedItem: null,
      sortBy: null,
      tableSidebarOpened: false,
      totalRows: 0,
      itemToDelete: {},
    };
  },
  methods: {
    clearFilters() {
      this.filterData = null;
      this.fetchData();
      this.$refs.usuarioFilter.clearForm();
    },
    clearSelected() {
      this.selectedItem = null;
    },
    closeSidebar() {
      this.tableSidebarOpened = false;
      this.clearSelected();
    },
    deleteUser(marcadoria) {
      this.itemToDelete = marcadoria.item;
      this.closeSidebar();
    },
    editUser(marcadoria) {
      this.closeSidebar();
      this.$router.push({
        name: "TeusEditar",
        params: { id: marcadoria.item.id },
      });
    },
    fetchData() {
      this.closeSidebar();
      this.isLoading = true;

      UsuarioService.getUsers({
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        ...this.filterData,
      })
        .then((resposta) => {
          this.isFiltered = !!this.filterData;
          this.items = [];
          UsuarioService.getTotalUsers({
            ...this.filterData,
          }).then((resposta) => {
            this.totalRows = resposta.data;
          });
          resposta.data.forEach((usuario) => {
            let usuarioArray = {
              firstName: usuario.firstName,
              lastName: usuario.lastName,
              email: usuario.email,
              perfil: "",
            };
            UsuarioService.findById(usuario.id).then((resposta) => {
              resposta.data.forEach((papel) => {
                if (papel.name === "ROLE_DOCAS") {
                  usuarioArray.perfil = "DOCAS";
                }
                if (papel.name === "ROLE_RECEITA") {
                  usuarioArray.perfil = "Receita Federal";
                }
              });
            });
            this.items.push(usuarioArray);
          });
        })
        .catch(() =>
          ToastHelper.dangerMessage(
            "Houve algum erro ao carregar dados dos Usuários"
          )
        )
        .finally(() => (this.isLoading = false));
    },
    filterFunction(row) {
      this.sortBy = row.sortBy;
      this.orderBy = row.sortDesc ? "desc" : "asc";
      this.fetchData();
    },
    filterPage({ nome, sobrenome, email }) {
      this.filterSidebarOpened = false;
      this.filterData = null;
      this.filterData = { nome, sobrenome, email };
      this.resetData();
      this.fetchData();
    },
    resetData() {
      const { currentPage } = this.$options.data.call(this);
      this.currentPage = currentPage;
    },
    selectedItems(items) {
      this.selectedItem = items[0];
      this.tableSidebarOpened = !!items.length;
    },
  },
  created() {
    this.fetchData();
    UsuarioService.getCurrentUser().then((resposta) => {
      this.papelUsuario = UsuarioHelper.getPapelUsuario(resposta.data);
    });
  },
};
</script>
<style lang="scss" scoped>
.clear-filters {
  cursor: pointer;
  width: 25px;
  height: 25px;
}
.user-container {
  grid-template-rows: minmax(auto, auto) 1fr;
}
.screen-title {
  min-height: 45px;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.custom-button {
  min-height: 45px;
  min-width: 45px;
  background-color: $bahama-blue;
}
</style>
