import { CustomAxios } from './axios';

export const OperacaoPortuariaMercadoriaService = {
  criar: ({ container, dataOperacao, dta, localOrigem, operacao_portuaria_fk, mercadoria, observacoes, operador, placaReboque, placaTrator }) =>
    CustomAxios.post('operacoesPortuariasMercadorias', {
      container,
      dataOperacao,
      dta,
      localOrigem: localOrigem.id,
      observacoes,
      operador: operador.id,
      placaReboque,
      placaTrator,
      mercadoria,
      operacao_portuaria_fk
    }),
  excluirPorId: ({ movimentacaoId }) => CustomAxios.delete(`operacoesPortuariasMercadorias/${movimentacaoId}`),
  get: ({ currentPage, pageSize, sortBy, orderBy, empresa, dta, operador, container }) => {
    let url = `operacoesPortuariasMercadorias?page=${currentPage - 1}&size=${pageSize}`;
    if (sortBy && orderBy) url += `&sort=${sortBy},${orderBy}`;
    if (empresa) url += `&empresa=${empresa}`;
    if (dta) url += `&dta=${dta}`;
    if (operador) url += `&operador=${operador}`;
    if (container) url += `&container=${container}`;
    return CustomAxios.get(url);
  },
  getDta: ({ dta }) => {
    let url = `operacoesPortuarias/buscar-por-dta/${dta}`;
    return CustomAxios.get(url);
  },
  getDtaMercadoria: ({ dta }) => {
    let url = `operacoesPortuarias/encontrar-containers-mercadoria-por-dta/${dta}`;
    return CustomAxios.get(url);
  },
  getAcoes: () => {
    let url = `acoes`;
    return CustomAxios.get(url);
  },
  buscarPorId: ({ movimentacaoId }) => CustomAxios.get(`operacoesPortuariasMercadorias/buscar-por-id/${movimentacaoId}`),
  atualizar: ({ id, container, dataOperacao, dta, localOrigem, operacao_portuaria_fk, mercadoria, observacoes, operador, placaReboque, placaTrator }) =>
    CustomAxios.put(`operacoesPortuariasMercadorias/${id}`, {
      container,
      dataOperacao,
      dta,
      localOrigem: localOrigem.id,
      observacoes,
      operador: operador.id,
      placaReboque,
      placaTrator,
      mercadoria,
      operacao_portuaria_fk
    }),
};
