<template>
  <div class="p-3 position-relative">
    <div class="d-flex justify-content-between mb-3">
      <div
        class="
          screen-title
          w-100
          d-flex
          align-items-center
          justify-content-center
        "
      >
        <h4 class="mb-0">Relatório de Operações Portuárias</h4>
      </div>
    </div>

    <div class="card-container">
      <div class="bg-white rounded shadow-sm">
        <div v-if="isProcessando" class="d-flex justify-content-center mb-3">
          <div>
            <div class="d-flex justify-content-center mb-3">
              <CustomLoading />
            </div>
            <h6>Processando, aguarde</h6>
          </div>
        </div>
        <b-form
          @submit.prevent="exportarRelatorio"
          class="w-100 h-100 p-4 d-flex flex-column justify-content-between"
        >
          <div class="row">
            <div class="col-lg-12 mb-3">
              <label class="required">Agrupar por</label>
              <b-form-radio-group
                id="radio_group_agrupamento"
                v-model="relatorio.tipoAgrupamento"
                :options="optionsAgrupamento"
                name="radio_options_agrupamento"
                value-field="id"
                text-field="descricao"
              ></b-form-radio-group>
              <b-form-invalid-feedback
                v-if="$v.relatorio.tipoAgrupamento.$error"
                :state="$v.relatorio.tipoAgrupamento.$error ? false : null"
              >
                <div v-if="!$v.relatorio.tipoAgrupamento.required">
                  É necessário informar o tipo de agrupamento
                </div>
              </b-form-invalid-feedback>
            </div>

            <div class="col-lg-4 mb-3">
              <label>Data de Operação:</label>
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6 mb-3">
                      <label class="required">Início</label>
                      <date-picker
                        :config="options"
                        id="data_operacao_inicio"
                        v-model="relatorio.dataOperacaoInicio"
                        placeholder="dd/MM/aaaa"
                      ></date-picker>
                    </div>

                    <div class="col-lg-6 mb-3">
                      <label>Fim</label>
                      <date-picker
                        :config="options"
                        id="data_operacao_fim"
                        v-model="relatorio.dataOperacaoFim"
                        placeholder="dd/MM/aaaa"
                      ></date-picker>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12 mb-3">
              <label class="required">Exportar para</label>
              <b-form-radio-group
                id="radio_group_tipo_exportacao"
                v-model="relatorio.tipoExportacao"
                :options="optionsTipoExportacao"
                name="radio_options_tipo_exportacao"
                value-field="text"
                text-field="value"
                disabled-field="notEnabled"
              ></b-form-radio-group>
            </div>
          </div>

          <div class="pt-4 w-100 d-flex justify-content-center">
            <button type="button" class="btn btn-light" @click="limpar()">
              <i class="fas fa-times"></i> Limpar
            </button>
            <button
              type="submit"
              class="btn btn-success"
              :disabled="$v.relatorio.$invalid || botaoDesabilitado"
            >
              <i class="fas fa-check"></i> Exportar Relatório
            </button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { RelatorioService } from "@/services";
import ToastHelper from "../../helpers/toastHelper";
import { required } from "vuelidate/lib/validators";
import { RelatorioMovimentacaoTipoAgrupamentoEnum } from "@/domain/enums.js";
import moment from "moment";
global.jQuery = require("jquery");
var $ = global.jQuery;

export default {
  components: {
    CustomLoading: () => import("@/components/Loading"),
  },
  data() {
    return {
      botaoDesabilitado: false,
      isProcessando: false,
      relatorio: {
        tipoAgrupamento: null,
        tipoExportacao: "PDF",
        dataOperacaoInicio: null,
        dataOperacaoFim: null,
      },
      options: {
        format: "DD/MM/YYYY",
        useCurrent: false,
      },
      optionsAgrupamento: [
        { text: "DTA", value: "DTA" },
        { text: "EMPRESA", value: "Empresa" },
        { text: "MERCADORIA", value: "Mercadoria" },
        { text: "DUE", value: "DU-E" },
        { text: "DECIMPORTACAO", value: "Declaração de Importação" },
      ],
      optionsTipoExportacao: [
        { text: "PDF", value: "PDF" },
        { text: "XLS", value: "XLS" },
      ],
    };
  },
  validations: {
    relatorio: {
      tipoAgrupamento: { required },
      tipoExportacao: { required },
    },
  },
  methods: {
    limpar() {
      this.relatorio.tipoAgrupamento = null;
      this.relatorio.dataOperacaoInicio = null;
      this.relatorio.dataOperacaoFim = null;
      $("#data_operacao_inicio").data("DateTimePicker").clear();
      $("#data_operacao_fim").data("DateTimePicker").clear();
      $("#data_perdimento_inicio").data("DateTimePicker").clear();
      $("#data_perdimento_fim").data("DateTimePicker").clear();
      $("#data_saida_inicio").data("DateTimePicker").clear();
      $("#data_saida_fim").data("DateTimePicker").clear();
    },
    exportarRelatorio() {
      let dataOperacaoInicioMoment = null;
      if (this.relatorio.dataOperacaoInicio != null) {
        dataOperacaoInicioMoment = moment(
          this.relatorio.dataOperacaoInicio,
          "DD/MM/YYYY"
        );
      }
      let dataOperacaoFimMoment = null;
      if (this.relatorio.dataOperacaoFim != null) {
        dataOperacaoFimMoment = moment(
          this.relatorio.dataOperacaoFim,
          "DD/MM/YYYY"
        );
      }
      if (dataOperacaoInicioMoment == null) {
        ToastHelper.warningMessage(
          "Não é possível gerar o relatório sem informar uma data de início para a Data da Operação"
        );
      } else if (
        dataOperacaoInicioMoment != null &&
        dataOperacaoFimMoment != null &&
        dataOperacaoInicioMoment > dataOperacaoFimMoment
      ) {
        ToastHelper.warningMessage(
          "Não é possível selecionar uma data de início posterior a data de término para a Data da Operação"
        );
      } else {
        this.isProcessando = true;
        this.botaoDesabilitado = true;
        RelatorioService.gerarRelatorioOperacaoPortuaria({
          tipoAgrupamento: this.relatorio.tipoAgrupamento,
          dataOperacaoInicio: this.relatorio.dataOperacaoInicio,
          dataOperacaoFim: this.relatorio.dataOperacaoFim,
          tipoExportacao: this.relatorio.tipoExportacao,
        })
          .then((response) => {
            let fileHeader = "application/pdf";
            if (this.relatorio.tipoExportacao === "XLS") {
              fileHeader = "application/vnd.ms-excel";
            }
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: fileHeader })
            );
            window.open(url);
            this.isProcessando = false;
            this.botaoDesabilitado = false;
          })
          .catch(() => {
            ToastHelper.dangerMessage(
              "Ocorreu um erro ao tentar gerar o relatório"
            );
            this.isProcessando = false;
            this.botaoDesabilitado = false;
          });
      }
    },
  },
  created() {
    this.optionsAgrupamento = RelatorioMovimentacaoTipoAgrupamentoEnum;
  },
};
</script>

<style lang="scss" scoped>
</style>
