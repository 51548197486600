import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);
const mutationsToCheck = ['auth/setLoggedUser', 'auth/setToken'];
const vuexPersist = new VuexPersist({
  key: process.env.VUE_APP_STORED_DATA,
  storage: localStorage,
  reducer: state => {
    return {
      auth: {
        loggedUser: state.auth.loggedUser,
        token: state.auth.token,
      },
    };
  },
  filter: mutation => mutationsToCheck.includes(mutation.type),
});

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules,
  plugins: [vuexPersist.plugin],
});
