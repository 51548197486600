import { CustomAxios } from './axios';

export const TeuService = {
  criar: (descricao) =>
    CustomAxios.post('teus', {
      descricao
    }),
  excluirPorId: ({ id }) => CustomAxios.delete(`teus/${id}`),
  get: ({ currentPage, pageSize, sortBy, orderBy, descricao }) => {
    let url = `teus?page=${currentPage - 1}&size=${pageSize}`;
    if (sortBy && orderBy) url += `&sort=${sortBy},${orderBy}`;
    if (descricao) url += `&descricao=${descricao}`;

    return CustomAxios.get(url);
  },
  buscarPorDescricao: (descricao) => {
    return CustomAxios.get('teus/buscar-por-descricao/' + descricao);
  },
  buscarPorId: ({ id }) => CustomAxios.get(`teus/buscar-por-id/${id}`),
  atualizar: ({ id, descricao }) =>
    CustomAxios.put(`teus/${id}`, {
      descricao
    }),
};
