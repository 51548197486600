import { RoleService } from '@/services';

export default {
  fetchRoles({ commit }) {
    return RoleService.getRoles().then(({ data }) => {
      commit('setRoles', data.roles);
      return data;
    });
  },
};
