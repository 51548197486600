import TokenHelper from '@/helpers/tokenHelper';
import { AuthService } from '@/services';

export default {
  authenticate({ commit }) {
    commit('setToken', TokenHelper.getToken());
  },
  login({ commit }, { email, password }) {
    return AuthService.login({ email, password }).then(({ data }) => {
      commit('setLoggedUser', data);
      commit('setToken', data.token);
      return data;
    });
  },
  logout({ commit }) {
    commit('setToken', null);
    localStorage.removeItem(process.env.VUE_APP_STORED_DATA);
  },
};
