import TokenHelper from '@/helpers/tokenHelper';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    loggedUser: {},
    token: TokenHelper.getToken(),
  },
  actions,
  getters,
  mutations,
};
