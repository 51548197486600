<template>
  <div
    class="w-100 h-100 p-3 position-relative overflow-auto bg-white shadow-sm d-flex align-items-start flex-column"
  >
    <div class="close-button pr-3 d-flex justify-content-end position-absolute">
      <b-button variant="link" class="close p-3" @click="$emit('close')">
        <span> &times; </span>
      </b-button>
    </div>
    <div v-if="isLoading" class="w-100 d-flex justify-content-center py-5">
      <custom-loading />
    </div>
    <div v-else class="d-flex align-items-start flex-column w-100 p-3">
      <span class="pt-2 break-word"
        ><b>Empresa</b>: {{ loadData("empresa").razaoSocial }}</span
      >

      <span class="pt-2 break-word"><b>DTA</b>: {{ loadData("dta") }}</span>

      <span class="pt-2 break-word"><b>DU-E</b>: {{ loadData("due") }}</span>

      <span class="pt-2 break-word"
        ><b>Declaração de Importação</b>:
        {{ loadData("declaracaoImportacao") }}</span
      >

      <span class="pt-2 break-word"><b>Destino</b>:</span>

      <span v-if="loadData('armazenamento') != null">{{
        loadData("armazenamento").descricao
      }}</span>

      <span class="pt-2 mb-3 break-word"><b>Contêiners</b>:</span>

      <table class="table table-bordered responsive_table">
        <thead>
          <tr>
            <th scope="col">Nro Contêiner</th>
            <th scope="col">Mercadoria</th>
            <th scope="col">Peso Bruto</th>
            <th scope="col">Data Entrada</th>
            <th scope="col">Data Perdimento</th>
            <th scope="col">Data Saída</th>
            <th scope="col">Local</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="container in loadData('containers')" :key="container.id">
            <td data-label="Nro Contêiner">{{ container.numero }}</td>
            <td data-label="Mercadoria">
              {{ container.mercadoria.descricao }}
            </td>
            <td data-label="Peso Bruto">{{ container.pesoBruto }}</td>
            <td data-label="Data Entrada">{{ container.dataEntrada }}</td>
            <td data-label="Data Perdimento">{{ container.dataPerdimento }}</td>
            <td data-label="Data Saída">{{ container.dataSaida }}</td>
            <td data-label="Local">
              <span v-if="container.local == null">Pátio</span>
              <span v-else>{{ container.local.descricao }}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pt-3 d-flex flex-row w-100 justify-content-center">
        <b-button
          class="custom-button rounded-circle mr-3"
          variant="outline-primary"
          @click="$emit('edit-movimentacao', { item: selectedItem })"
        >
          <b-icon-pencil />
        </b-button>
        <b-button
          class="custom-button rounded-circle"
          variant="outline-danger"
          disabled="disabled"
          @click="$emit('delete-movimentacao')"
        >
          <b-icon-trash />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { MovimentacaoService } from "@/services";
import formatarDTA from "@/util/formatacao/formatar_dta.js";

export default {
  components: {
    CustomLoading: () => import("@/components/Loading"),
  },
  props: {
    selectedItem: {
      type: Object,
    },
  },
  data() {
    return {
      loadedMovimentacao: null,
      isLoading: false,
    };
  },
  watch: {
    selectedItem(value) {
      if (value) this.fetchData();
    },
  },
  methods: {
    loadData(key) {
      return this.loadedMovimentacao ? this.loadedMovimentacao[key] : "";
    },
    fetchData() {
      this.isLoading = true;
      MovimentacaoService.buscarPorId({
        movimentacaoId: this.selectedItem.id,
      })
        .then(({ data }) => {
          this.loadedMovimentacao = data;
          this.loadedMovimentacao.dta = formatarDTA(
            this.loadedMovimentacao.dta
          );
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  min-height: 46px;
}
.close-button {
  right: 0;
}
</style>
