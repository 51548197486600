<template>
  <div class="p-3 h-100">
    <b-form
      @submit.prevent="$emit('filtered', operacao)"
      class="w-100 h-100 d-flex flex-column justify-content-between"
    >
      <div>
        <label class="mb-0 font-weight-bold" label-for="dta">DTA</label>
        <b-input
          id="dta"
          v-model="operacao.dta"
          type="text"
          v-maska="'##/#######-#'"
          placeholder="00/0000000-0"
        />

        <label for="container" class="mt-2 mb-0 font-weight-bold"
          >Contêiner</label
        >
        <b-input id="container" v-model="operacao.container" type="text" />

        <label for="operador" class="mt-2 mb-0 font-weight-bold"
          >Operador</label
        >
        <b-input id="container" v-model="operacao.operador" type="text" />

        <label for="empresa" class="mt-2 mb-0 font-weight-bold">Empresa</label>
        <b-input id="empresa" v-model="operacao.empresa" type="text" />
      </div>
      <div class="pt-4 w-100 d-flex justify-content-center">
        <b-button class="custom-button w-100" type="submit"> Filtrar </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isLoadingRoles: false,
      operacao: {
        dta: null,
        container: null,
        operador: null,
        empresa: null,
      },
    };
  },
  methods: {
    clearForm() {
      this.operacao = {
        dta: null,
        container: null,
        operador: null,
        empresa: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  background-color: $bahama-blue;
  color: $white;
}
</style>