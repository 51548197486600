import { CustomAxios } from './axios';

export const EmpresaService = {
  criar: ({ razaoSocial, cnpj }) =>
    CustomAxios.post('empresas', {
      razaoSocial,
      cnpj
    }),
  excluirPorId: ({ empresaId }) => CustomAxios.delete(`empresas/${empresaId}`),
  getEmpresas: ({ currentPage, pageSize, sortBy, orderBy, razaoSocial, cnpj }) => {
    let url = `empresas?page=${currentPage - 1}&size=${pageSize}`;
    if (sortBy && orderBy) url += `&sort=${sortBy},${orderBy}`;
    if (razaoSocial) url += `&razaoSocial=${razaoSocial}`;
    if (cnpj) url += `&cnpj=${cnpj}`;

    return CustomAxios.get(url);
  },
  buscarPorCnpjAtivo: ({ cnpj }) => {
    return CustomAxios.get('empresas/buscar-por-cnpj-ativo/' + cnpj);
  },
  buscarPorId: ({ empresaId }) => CustomAxios.get(`empresas/buscar-por-id/${empresaId}`),
  atualizar: ({ empresaId, razaoSocial, cnpj }) =>
    CustomAxios.put(`empresas/${empresaId}`, {
      razaoSocial,
      cnpj
    }),
};
