import { oidcSettings } from '@/config/oidc';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';
// NOTE: If you want to customize how tokens are stored you can use WebStorageStateStore from oidc-client (see bellow)
// Default storage is window.localStorage
// import { WebStorageStateStore } from 'oidc-client'

export default vuexOidcCreateStoreModule(
  oidcSettings,
  // NOTE: If you do not want to use localStorage for tokens, in stead of just passing oidcSettings, you can
  // spread your oidcSettings and define a userStore of your choice
  // {
  //   ...oidcSettings,
  //   userStore: new WebStorageStateStore({ store: window.sessionStorage })
  // },
  // Optional OIDC store settings
  {
    namespaced: true,
    dispatchEventsOnWindow: true,
  },
  // Optional OIDC event listeners
  {
    userLoaded: user => {
      localStorage.access_token = user.access_token;
    },
    userUnloaded: () => console.log('OIDC user is unloaded'),
    accessTokenExpiring: () => console.log('Access token will expire'),
    accessTokenExpired: () => console.log('Access token did expire'),
    silentRenewError: () => console.log('OIDC user is unloaded'),
    userSignedOut: () => console.log('OIDC user is signed out'),
    oidcError: payload => console.log('OIDC error', JSON.stringify(payload.error)),
    automaticSilentRenewError: payload => console.log('OIDC automaticSilentRenewError', payload),
  }
);
