<template>
  <div class="p-3 h-100">
    <b-form
      @submit.prevent="$emit('filtered', empresa)"
      class="w-100 h-100 d-flex flex-column justify-content-between"
    >
      <div>
        <label class="mb-0 font-weight-bold" label-for="razaoSocial"
          >Razão Social</label
        >
        <b-input id="razaoSocial" v-model="empresa.razaoSocial" type="text" />

        <label class="mt-3 mb-0 font-weight-bold" label-for="cnpj">CNPJ</label>
        <b-input id="cnpj" v-model="empresa.cnpj" type="text" />
      </div>
      <div class="pt-4 w-100 d-flex justify-content-center">
        <b-button class="custom-button w-100" type="submit"> Filtrar </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoadingRoles: false,
      empresa: {
        razaoSocial: null,
        cnpj: null,
      },
    };
  },
  methods: {
    clearForm() {
      this.empresa = {
        razaoSocial: null,
        cnpj: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  background-color: $bahama-blue;
  color: $white;
}
</style>
