import TokenHelper from '@/helpers/tokenHelper';

export default {
  setLoggedUser(state, user) {
    state.loggedUser = { ...user };
  },
  setToken(state, token) {
    token ? TokenHelper.saveToken(token) : TokenHelper.removeToken();
    state.token = token;
  },
};
