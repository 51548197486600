import { render, staticRenderFns } from "./ArmazenamentoFilter.vue?vue&type=template&id=04f86b0b&scoped=true&"
import script from "./ArmazenamentoFilter.vue?vue&type=script&lang=js&"
export * from "./ArmazenamentoFilter.vue?vue&type=script&lang=js&"
import style0 from "./ArmazenamentoFilter.vue?vue&type=style&index=0&id=04f86b0b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04f86b0b",
  null
  
)

export default component.exports