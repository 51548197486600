import { render, staticRenderFns } from "./DashboardPadrao.vue?vue&type=template&id=1a2b5579&scoped=true&"
var script = {}
import style0 from "./DashboardPadrao.vue?vue&type=style&index=0&id=1a2b5579&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a2b5579",
  null
  
)

export default component.exports