<template>
  <div class="p-3 position-relative">
    <div class="d-flex justify-content-between mb-3">
      <div
        class="screen-title w-100 d-flex align-items-center justify-content-center"
      >
        <h4 class="mb-0">Adicionar Empresa</h4>
      </div>
      <div class="position-relative">
        <b-button
          class="custom-button d-flex align-items-center justify-content-center rounded-circle"
          :to="{ name: 'EmpresaPesquisar' }"
          v-b-tooltip.hover.right="'Voltar para lista de empresas'"
        >
          <b-icon-arrow-left />
        </b-button>
      </div>
    </div>

    <div class="card-container">
      <div v-if="isCreatingUser" class="d-grid py-5 h-100">
        <CustomLoading />
      </div>
      <div v-else class="bg-white rounded shadow-sm">
        <empresaFormulario @change="save" ref="empresaFormulario" />
      </div>
    </div>
  </div>
</template>

<script>
import { EmpresaService } from "@/services";
import ToastHelper from "../../helpers/toastHelper";

export default {
  components: {
    CustomLoading: () => import("@/components/Loading"),
    empresaFormulario: () => import("./components/EmpresaFormulario"),
  },
  data() {
    return {
      isCreatingUser: false,
    };
  },
  methods: {
    save(empresa) {
      EmpresaService.criar(empresa)
        .then(() => {
          ToastHelper.successMessage(
            `${empresa.razaoSocial} cadastrado com sucesso.`
          );
          this.$refs.empresaFormulario.reset();
          setTimeout(
            () => this.$router.push({ name: "EmpresaPesquisar" }),
            1500
          );
        })
        .catch((error) => {
          if (error.response.data.errors.length > 0) {
            error.response.data.errors.forEach((error) => {
              ToastHelper.dangerMessage(error);
            });
          } else {
            ToastHelper.dangerMessage(error.response.data.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  .custom-card {
    width: 75%;
    @media only screen and (min-width: $screen-sm) {
      width: 50%;
    }
    @media only screen and (min-width: $screen-md) {
      width: 40%;
    }
  }
}
.custom-button {
  min-height: 45px;
  min-width: 45px;
  z-index: 1;
  background-color: $bahama-blue;
}
</style>
