<template>
  <div
    class="w-100 h-100 p-3 position-relative overflow-auto bg-white shadow-sm d-flex align-items-start flex-column"
  >
    <div class="close-button pr-3 d-flex justify-content-end position-absolute">
      <b-button variant="link" class="close p-3" @click="$emit('close')">
        <span> &times; </span>
      </b-button>
    </div>
    <div v-if="isLoading" class="w-100 d-flex justify-content-center py-5">
      <custom-loading />
    </div>
    <div v-else class="d-flex align-items-start flex-column w-100 p-3">
      <span class="pt-2 break-word"
        ><b>Razão Social</b>: {{ loadData("razaoSocial") }}</span
      >
      <span class="pt-2 break-word"><b>CNPJ</b>: {{ loadData("cnpj") }}</span>
      <div class="pt-3 d-flex flex-row w-100 justify-content-center">
        <b-button
          class="custom-button rounded-circle mr-3"
          variant="outline-primary"
          @click="$emit('edit-user', { item: selectedItem })"
        >
          <b-icon-pencil />
        </b-button>
        <b-button
          class="custom-button rounded-circle"
          variant="outline-danger"
          @click="$emit('delete-user')"
        >
          <b-icon-trash />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { OperadorPortuarioService } from "@/services";

export default {
  components: {
    CustomLoading: () => import("@/components/Loading"),
  },
  props: {
    selectedItem: {
      type: Object,
    },
  },
  data() {
    return {
      loadedEmpresa: null,
      isLoading: false,
    };
  },
  watch: {
    selectedItem(value) {
      if (value) this.fetchData();
    },
  },
  methods: {
    loadData(key) {
      return this.loadedEmpresa ? this.loadedEmpresa[key] : "";
    },
    fetchData() {
      this.isLoading = true;
      OperadorPortuarioService.buscarPorId({
        operadorPortuarioId: this.selectedItem.id,
      })
        .then(({ data }) => {
          this.loadedEmpresa = data;
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  min-height: 46px;
}
.close-button {
  right: 0;
}
</style>
