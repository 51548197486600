import Vue from 'vue'
import VueRouter from 'vue-router'
import { UsuarioService } from "@/services"
import UsuarioHelper from "@/helpers/usuarioHelper"
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import store from '@/store'
import PesquisarUsuario from '../views/usuarios/PesquisarUsuario';
import DashboardPadrao from '../views/dashboard/DashboardPadrao';
import PesquisarEmpresa from '../views/empresas/PesquisarEmpresa';
import AdicionarEmpresa from '../views/empresas/AdicionarEmpresa';
import EditarEmpresa from '../views/empresas/EditarEmpresa';
import PesquisarOperadorPortuario from '../views/operadoresPortuarios/PesquisarOperadorPortuario';
import AdicionarOperadorPortuario from '../views/operadoresPortuarios/AdicionarOperadorPortuario';
import EditarOperadorPortuario from '../views/operadoresPortuarios/EditarOperadorPortuario';
import PesquisarArmazenamento from '../views/locais-armazenamento/PesquisarArmazenamento';
import AdicionarArmazenamento from '../views/locais-armazenamento/AdicionarArmazenamento';
import EditarArmazenamento from '../views/locais-armazenamento/EditarArmazenamento';
import PesquisarMercadoria from '../views/mercadorias/PesquisarMercadoria';
import AdicionarMercadoria from '../views/mercadorias/AdicionarMercadoria';
import EditarMercadoria from '../views/mercadorias/EditarMercadoria';
import PesquisarMovimentacao from '@/views/movimentacoes/PesquisarMovimentacao';
import AdicionarMovimentacao from '@/views/movimentacoes/AdicionarMovimentacao';
import RelatorioMovimentacao from '@/views/movimentacoes/RelatorioMovimentacao';
import EditarMovimentacao from '@/views/movimentacoes/EditarMovimentacao';
import PesquisarTeu from '../views/teus/PesquisarTeu';
import AdicionarTeu from '../views/teus/AdicionarTeu';
import EditarTeu from '../views/teus/EditarTeu';
import PesquisarOperacaoPortuaria from '@/views/operacaoPortuaria/PesquisarOperacaoPortuaria';
import AdicionarOperacaoPortuaria from '@/views/operacaoPortuaria/AdicionarOperacaoPortuaria';
import EditarOperacaoPortuaria from '@/views/operacaoPortuaria/EditarOperacaoPortuaria';
import RelatorioOperacaoPortuaria from '@/views/operacaoPortuaria/RelatorioOperacaoPortuaria';
import PesquisarOperacaoPortuariaMercadoria from '@/views/operacaoPortuariaMercadoria/PesquisarOperacaoPortuariaMercadoria';
import AdicionarOperacaoPortuariaMercadoria from '@/views/operacaoPortuariaMercadoria/AdicionarOperacaoPortuariaMercadoria';
import RelatorioOperacaoPortuariaMercadoria from '@/views/operacaoPortuariaMercadoria/RelatorioOperacaoPortuariaMercadoria';
import EditarOperacaoPortuariaMercadoria from '@/views/operacaoPortuariaMercadoria/EditarOperacaoPortuariaMercadoria';

Vue.use(VueRouter)

const PERFIL_RECEITA = "ROLE_RECEITA";

const routes = [
  {
    path: '/acesso-negado',
    name: 'acessoNegado',
    component: () => import('@/views/AcessoNegado')
  },
  {
    path: '/oidc-callback',
    name: 'oidcCallback',
    component: () => import('@/config/Oidc/OidcCallback'),
  },
  {
    path: '/oidc-popup-callback',
    name: 'oidcPopupCallback',
    component: () => import('@/config/Oidc/OidcPopupCallback'),
  },
  {
    path: '/',
    name: 'Layout',
    component: () => import('@/views/Layout'),
    redirect: { name: 'DashboardPadrao' },
    children: [
      {
        path: '/usuarios',
        name: 'PesquisarUsuario',
        component: PesquisarUsuario,
        beforeEnter: (to, from, next) => {
          localStorage.rotaAnterior = to.name;
          UsuarioService.getCurrentUser().then((resposta) => {
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/',
        name: 'DashboardPadrao',
        component: DashboardPadrao
      },
      {
        path: '/empresas',
        name: 'EmpresaPesquisar',
        component: PesquisarEmpresa,
      },
      {
        path: '/empresas/adicionar',
        name: 'EmpresaAdicionar',
        component: AdicionarEmpresa,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/empresas/editar/:empresaId',
        name: 'EmpresaEditar',
        component: EditarEmpresa,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/operadoresPortuarios',
        name: 'OperadorPortuarioPesquisar',
        component: PesquisarOperadorPortuario,
      },
      {
        path: '/operadoresPortuarios/adicionar',
        name: 'OperadorPortuarioAdicionar',
        component: AdicionarOperadorPortuario,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/operadoresPortuarios/editar/:operadorPortuarioId',
        name: 'OperadorPortuarioEditar',
        component: EditarOperadorPortuario,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/locais-armazenamento',
        name: 'ArmazenamentoPesquisar',
        component: PesquisarArmazenamento,
      },
      {
        path: '/locais-armazenamento/adicionar',
        name: 'ArmazenamentoAdicionar',
        component: AdicionarArmazenamento,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/locais-armazenamento/editar/:id',
        name: 'ArmazenamentoEditar',
        component: EditarArmazenamento,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/mercadorias',
        name: 'MercadoriasPesquisar',
        component: PesquisarMercadoria,
      },
      {
        path: '/mercadorias/adicionar',
        name: 'MercadoriasAdicionar',
        component: AdicionarMercadoria,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/mercadorias/editar/:id',
        name: 'MercadoriasEditar',
        component: EditarMercadoria,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/movimentacoes',
        name: 'MovimentacaoPesquisar',
        component: PesquisarMovimentacao,
      },
      {
        path: '/movimentacoes/adicionar',
        name: 'MovimentacaoAdicionar',
        component: AdicionarMovimentacao,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/movimentacoes/relatorios',
        name: 'MovimentacaoRelatorio',
        component: RelatorioMovimentacao
      },
      {
        path: '/movimentacoes/editar/:movimentacaoId',
        name: 'MovimentacaoEditar',
        component: EditarMovimentacao,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/teus',
        name: 'TeusPesquisar',
        component: PesquisarTeu,
      },
      {
        path: '/teus/adicionar',
        name: 'TeusAdicionar',
        component: AdicionarTeu,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/teus/editar/:id',
        name: 'TeusEditar',
        component: EditarTeu,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/operacoes-portuarias',
        name: 'PesquisarOperacaoPortuaria',
        component: PesquisarOperacaoPortuaria,
      },
      {
        path: '/operacoes-portuarias/adicionar',
        name: 'AdicionarOperacaoPortuaria',
        component: AdicionarOperacaoPortuaria,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/operacoes-portuarias/editar/:id',
        name: 'EditarOperacaoPortuaria',
        component: EditarOperacaoPortuaria,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/operacoes-portuarias/relatorios',
        name: 'RelatorioOperacaoPortuaria',
        component: RelatorioOperacaoPortuaria
      },
      {
        path: '/operacoes-portuarias-mercadoria',
        name: 'PesquisarOperacaoPortuariaMercadoria',
        component: PesquisarOperacaoPortuariaMercadoria,
      },
      {
        path: '/operacoes-portuarias-mercadoria/adicionar',
        name: 'AdicionarOperacaoPortuariaMercadoria',
        component: AdicionarOperacaoPortuariaMercadoria,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/operacoes-portuarias-mercadoria/editar/:id',
        name: 'EditarOperacaoPortuariaMercadoria',
        component: EditarOperacaoPortuariaMercadoria,
        beforeEnter: (to, from, next) => {
          UsuarioService.getCurrentUser().then((resposta) => {
            localStorage.rotaAnterior = to.name;
            let papel = UsuarioHelper.getPapelUsuario(resposta.data);
            if (papel === PERFIL_RECEITA) next({ name: 'acessoNegado' })
            next();
          });
        }
      },
      {
        path: '/operacoes-portuarias-mercadoria/relatorios',
        name: 'RelatorioOperacaoPortuariaMercadoria',
        component: RelatorioOperacaoPortuariaMercadoria
      },
    ],
  },
  {
    path: '*',
    redirect: { name: 'Layout' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

export default router
