<template>
  <div class="p-3 position-relative">
    <div class="d-flex justify-content-between mb-3">
      <div
        class="screen-title w-100 d-flex align-items-center justify-content-center"
      >
        <h4 class="mb-0">Editar Armazenamento</h4>
      </div>
      <div class="position-relative">
        <b-button
          class="custom-button d-flex align-items-center justify-content-center rounded-circle"
          :to="{ name: 'ArmazenamentoPesquisar' }"
          v-b-tooltip.hover.right="'Voltar para lista de armazenamentos'"
        >
          <b-icon-arrow-left />
        </b-button>
      </div>
    </div>

    <div class="card-container">
      <div v-if="isLoading" class="d-grid py-5 h-100">
        <CustomLoading />
      </div>
      <div v-else class="bg-white rounded shadow-sm">
        <armazenamentoFormulario
          @change="save"
          :mountedForm="loaded"
          ref="armazenamentoFormulario"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ArmazenamentoService } from "../../services/armazenamentoService";
import ToastHelper from "../../helpers/toastHelper";

export default {
  components: {
    CustomLoading: () => import("@/components/Loading"),
    armazenamentoFormulario: () =>
      import("./components/ArmazenamentoFormulario"),
  },
  data: function () {
    return {
      loaded: null,
      armazenamentoId: this.$route.params.id,
      isLoading: false,
    };
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      ArmazenamentoService.buscarPorId({ id: this.armazenamentoId })
        .then((resposta) => {
          this.loaded = resposta.data;
        })
        .finally(() => (this.isLoading = false));
    },
    save(armazenamento) {
      ArmazenamentoService.atualizar({
        id: this.armazenamentoId,
        ...armazenamento 
      })
        .then(() => {
          ToastHelper.successMessage(`${armazenamento.descricao} editado com sucesso.`);

          setTimeout(
            () => this.$router.push({ name: "ArmazenamentoPesquisar" }),
            1500
          );
        })
        .catch((error) => {
          ToastHelper.dangerMessage(error.response.data.message);
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  .custom-card {
    width: 75%;
    @media only screen and (min-width: $screen-sm) {
      width: 50%;
    }
    @media only screen and (min-width: $screen-md) {
      width: 40%;
    }
  }
}
.custom-button {
  min-height: 45px;
  min-width: 45px;
  z-index: 1;
  background-color: $bahama-blue;
}
</style>
