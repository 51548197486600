import { CustomAxios } from './axios';

export const RelatorioService = {
  gerarRelatorioOperacaoPortuariaMercadoria: ({
    tipoAgrupamento,
    dataOperacaoInicio,
    dataOperacaoFim,
    tipoExportacao
  }) => {
    let url = `/operacoesPortuariasMercadorias/gerar-relatorio?tipoAgrupamento=${tipoAgrupamento}`;
    url += `&tipoExportacao=${tipoExportacao}`;
    if (dataOperacaoInicio != null) {
      url += `&dataOperacaoInicio=${dataOperacaoInicio}`;
    }
    if (dataOperacaoFim != null) {
      url += `&dataOperacaoFim=${dataOperacaoFim}`;
    }
    return CustomAxios.get(url, { responseType: 'blob' });
  },
  gerarRelatorioOperacaoPortuaria: ({
    tipoAgrupamento,
    dataOperacaoInicio,
    dataOperacaoFim,
    tipoExportacao
  }) => {
    let url = `/operacoesPortuarias/gerar-relatorio?tipoAgrupamento=${tipoAgrupamento}`;
    url += `&tipoExportacao=${tipoExportacao}`;
    if (dataOperacaoInicio != null) {
      url += `&dataOperacaoInicio=${dataOperacaoInicio}`;
    }
    if (dataOperacaoFim != null) {
      url += `&dataOperacaoFim=${dataOperacaoFim}`;
    }
    return CustomAxios.get(url, { responseType: 'blob' });
  },
  gerarRelatorioMovimentacao: ({
    tipoAgrupamento,
    dataEntradaInicio,
    dataEntradaFim,
    dataSaidaInicio,
    dataSaidaFim,
    dataPerdimentoInicio,
    dataPerdimentoFim,
    tipoExportacao
  }) => {
    let url = `/movimentacoes/gerar-relatorio?tipoAgrupamento=${tipoAgrupamento}`;
    url += `&tipoExportacao=${tipoExportacao}`;
    if (dataEntradaInicio != null) {
      url += `&dataEntradaInicio=${dataEntradaInicio}`;
    }
    if (dataEntradaFim != null) {
      url += `&dataEntradaFim=${dataEntradaFim}`;
    }
    if (dataSaidaInicio != null) {
      url += `&dataSaidaInicio=${dataSaidaInicio}`;
    }
    if (dataSaidaFim != null) {
      url += `&dataSaidaFim=${dataSaidaFim}`;
    }
    if (dataPerdimentoInicio != null) {
      url += `&dataPerdimentoInicio=${dataPerdimentoInicio}`;
    }
    if (dataPerdimentoFim != null) {
      url += `&dataPerdimentoFim=${dataPerdimentoFim}`;
    }
    return CustomAxios.get(url, { responseType: 'blob' });
  },
}