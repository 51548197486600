import ToastHelper from '@/helpers/toastHelper';
import TokenHelper from '@/helpers/tokenHelper';
import router from '@/router';
import axios from 'axios';

const CustomAxios = axios.create({
  baseURL: window.$environment.API_URL,
});

CustomAxios.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json';
    const token = TokenHelper.getToken();

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

CustomAxios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      if (router.currentRoute.name !== 'Login') {
        ToastHelper.dangerMessage('Seu token expirou, realize o login novamente.');
        router.push({ name: 'Logout' });
      }
    }

    return Promise.reject(error);
  }
);

export { CustomAxios };
