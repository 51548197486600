import { CustomAxios } from './axios';

export const ArmazenamentoService = {
  criar: ({ descricao, autorizado_ea }) =>
    CustomAxios.post('armazenamento', {
      descricao,
      autorizado_ea
    }),
  getAutorizadosEA: () => {
    return CustomAxios.get("/armazenamento/lista-autorizados-ea");
  },
  excluirPorId: ({ id }) => CustomAxios.delete(`armazenamento/${id}`),
  get: ({ currentPage, pageSize, sortBy, orderBy, descricao, autorizado_ea }) => {
    let url = `armazenamento?page=${currentPage - 1}&size=${pageSize}`;
    if (sortBy && orderBy) url += `&sort=${sortBy},${orderBy}`;
    if (descricao) url += `&descricao=${descricao}`;
    if (autorizado_ea) url += `&autorizado_ea=${autorizado_ea}`;

    return CustomAxios.get(url);
  },
  buscarPorDescricao: (descricao) => {
    return CustomAxios.get('armazenamento/buscar-por-descricao/' + descricao);
  },
  buscarPorId: ({ id }) => CustomAxios.get(`armazenamento/buscar-por-id/${id}`),
  atualizar: ({ id, descricao, autorizado_ea }) =>
    CustomAxios.put(`armazenamento/${id}`, {
      descricao,
      autorizado_ea
    }),
};
