<template>
  <div class="p-3 position-relative">
    <div class="d-flex justify-content-between mb-3">
      <div
        class="screen-title w-100 d-flex align-items-center justify-content-center"
      >
        <h4 class="mb-0">Adicionar Mercadoria</h4>
      </div>
      <div class="position-relative">
        <b-button
          class="custom-button d-flex align-items-center justify-content-center rounded-circle"
          :to="{ name: 'MercadoriasPesquisar' }"
          v-b-tooltip.hover.right="'Voltar para lista de Mercadorias'"
        >
          <b-icon-arrow-left />
        </b-button>
      </div>
    </div>

    <div class="card-container">
      <div v-if="isCreating" class="d-grid py-5 h-100">
        <CustomLoading />
      </div>
      <div v-else class="bg-white rounded shadow-sm">
        <formularioMercadoria @change="save" ref="formularioMercadoria" />
      </div>
    </div>
  </div>
</template>

<script>
import { MercadoriaService } from "../../services/mercadoriaService";
import ToastHelper from "../../helpers/toastHelper";

export default {
  components: {
    CustomLoading: () => import("@/components/Loading"),
    formularioMercadoria: () => import("./components/FormularioMercadoria"),
  },
  data: function () {
    return {
      isCreating: false,
    };
  },
  methods: {
    save(descricao) {
      MercadoriaService.criar(descricao)
        .then(() => {
          ToastHelper.successMessage(`${descricao} cadastrado com sucesso.`);
          setTimeout(
            () => this.$router.push({ name: "MercadoriasPesquisar" }),
            1500
          );
        })
        .catch((error) => {
          ToastHelper.dangerMessage(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  .custom-card {
    width: 75%;
    @media only screen and (min-width: $screen-sm) {
      width: 50%;
    }
    @media only screen and (min-width: $screen-md) {
      width: 40%;
    }
  }
}
.custom-button {
  min-height: 45px;
  min-width: 45px;
  z-index: 1;
  background-color: $bahama-blue;
}
</style>
