import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugins/i18n'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Maska from 'maska'
import Vuelidate from 'vuelidate'
import ToastHelper from "./helpers/toastHelper";

import datePicker from 'vue-bootstrap-datetimepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
Vue.use(datePicker);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/global.scss';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Maska)
Vue.use(Vuelidate)

import { ValidationProvider, ValidationObserver } from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.config.productionTip = false;

import { required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { configure } from 'vee-validate';

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
    dirty: ['is-dirty', 'is-dirty'],
  }
})

extend("required", {
  ...required,
  message: "Esse campo é obrigatório",
});

import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

ToastHelper.configure(app);
