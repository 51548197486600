<template>
  <b-modal
    :ok-variant="variant"
    :ok-disabled="false"
    :size="size"
    footer-class="px-3 px-md-5 py-2 my-4 border-top-0 d-flex flex-column-reverse justify-content-center align-items-center flex-sm-row"
    header-class="pl-3 pl-md-4 pt-3 pb-2 border-bottom-0"
    cancel-variant="outline-secondary"
    body-class="py-0 px-3 px-md-5"
    centered
    v-bind="$attrs"
    v-on="$listeners"
    @hidden="resetForm"
  >
    <div class="d-flex flex-column align-items-center">
      <b-icon
        :icon="icon"
        :variant="iconVariant"
        class="icon mb-3 mt-0"
        :class="[iconClass]"
      />
      <h3
        class="mb-3 font-weight-bold text-center break-word"
        v-html="customTitle"
      ></h3>
      <p
        class="mx-4 mb-3 text-center break-word"
        v-if="description"
        v-html="description"
      ></p>
      <slot />
    </div>
  </b-modal>
</template>

<script>
import { sameAs } from "vuelidate/lib/validators";

export default {
  inheritAttrs: false,
  props: {
    customTitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    verificationText: {
      type: String,
    },
    variant: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: "bell",
    },
    iconVariant: {
      type: String,
      default: "regular",
    },
    placeholder: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "smd",
    },
  },
  data() {
    return {
      form: {
        text: null,
      },
    };
  },
  validations: {
    form: {
      text: {
        sameAs: sameAs(function () {
          return this.verificationText;
        }),
      },
    },
  },
  methods: {
    resetForm() {
      const { form } = this.$options.data.call(this);
      this.form = form;
      this.$v.$reset();
    },
  },
  computed: {
    iconClass() {
      return `text-${this.variant}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  font-size: 3rem;
}

::v-deep input {
  min-height: 45px;
}

::v-deep footer {
  > button {
    width: 100%;
    margin: 1% 0 !important;
    min-height: 45px;
  }

  & :first-child {
    margin-top: 16px !important;
  }
}

@media only screen and (min-width: $screen-sm) {
  ::v-deep input {
    min-height: 36px;
    height: 36px;
  }
  ::v-deep footer {
    > button {
      margin: 0 !important;
      width: auto;
      min-height: auto !important;
    }

    & :first-child {
      margin-top: 0 !important;
      margin-right: 16px !important;
    }
  }
}
</style>
