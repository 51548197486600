import { CustomAxios } from './axios';

export const MercadoriaService = {
  criar: (descricao) =>
    CustomAxios.post('mercadorias', {
      descricao
    }),
  excluirPorId: ({ id }) => CustomAxios.delete(`mercadorias/${id}`),
  get: ({ currentPage, pageSize, sortBy, orderBy, descricao }) => {
    let url = `mercadorias?page=${currentPage - 1}&size=${pageSize}`;
    if (sortBy && orderBy) url += `&sort=${sortBy},${orderBy}`;
    if (descricao) url += `&descricao=${descricao}`;

    return CustomAxios.get(url);
  },
  buscarPorDescricao: (descricao) => {
    return CustomAxios.get('mercadorias/buscar-por-descricao/' + descricao);
  },
  buscarPorId: ({ id }) => CustomAxios.get(`mercadorias/buscar-por-id/${id}`),
  buscarLocalPorContainer: ({ id }) => CustomAxios.get(`mercadorias/buscar-local-por-container/${id}`),
  atualizar: ({ id, descricao }) =>
    CustomAxios.put(`mercadorias/${id}`, {
      descricao
    }),
};
