import { CustomAxios } from './axios';

export const OperacaoPortuariaService = {
  criar: ({ acao, container, dataOperacao, dta, movimentacao_fk, localDestino, localOrigem, observacoes, operador, placaReboque, placaTrator }) =>
    CustomAxios.post('operacoesPortuarias', {
      acao,
      container,
      dataOperacao,
      dta,
      movimentacao_fk,
      localDestino: localDestino.id,
      localOrigem: localOrigem.id,
      observacoes,
      operador: operador.id,
      placaReboque,
      placaTrator
    }),
  excluirPorId: ({ movimentacaoId }) => CustomAxios.delete(`operacoesPortuarias/${movimentacaoId}`),
  get: ({ currentPage, pageSize, sortBy, orderBy, empresa, dta, operador, container }) => {
    let url = `operacoesPortuarias?page=${currentPage - 1}&size=${pageSize}`;
    if (sortBy && orderBy) url += `&sort=${sortBy},${orderBy}`;
    if (empresa) url += `&empresa=${empresa}`;
    if (dta) url += `&dta=${dta}`;
    if (operador) url += `&operador=${operador}`;
    if (container) url += `&container=${container}`;
    return CustomAxios.get(url);
  },
  getDta: ({ dta }) => {
    let url = `movimentacoes/buscar-dta-movimentacao-nao-excluida/${dta}`;
    return CustomAxios.get(url);
  },
  getAcoes: () => {
    let url = `acoes`;
    return CustomAxios.get(url);
  },
  buscarPorId: ({ movimentacaoId }) => CustomAxios.get(`operacoesPortuarias/buscar-por-id/${movimentacaoId}`),
  atualizar: ({ movimentacaoId, acao, container, dataOperacao, dta, movimentacao_fk, localDestino, localOrigem, observacoes, operador, placaReboque, placaTrator }) =>
    CustomAxios.put(`operacoesPortuarias/${movimentacaoId}`, {
      acao,
      container,
      dataOperacao,
      dta,
      movimentacao_fk,
      localDestino: localDestino.id,
      localOrigem: localOrigem.id,
      observacoes,
      operador: operador.id,
      placaReboque,
      placaTrator
    }),
};