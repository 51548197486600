<template>
  <div class="p-3 h-100">
    <b-form
      @submit.prevent="$emit('filtered', usuario)"
      class="w-100 h-100 d-flex flex-column justify-content-between"
    >
      <div>
        <label class="mb-0 font-weight-bold" label-for="nome">Nome</label>
        <b-input id="nome" v-model="usuario.nome" type="text" />

        <label class="mb-0 font-weight-bold" label-for="sobrenome"
          >Sobrenome</label
        >
        <b-input id="sobrenome" v-model="usuario.sobrenome" type="text" />

        <label class="mt-3 mb-0 font-weight-bold" label-for="email"
          >E-mail</label
        >
        <b-input id="email" v-model="usuario.email" type="text" />
      </div>
      <div class="pt-4 w-100 d-flex justify-content-center">
        <b-button class="custom-button w-100" type="submit"> Filtrar </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoadingRoles: false,
      usuario: {
        nome: null,
        sobrenome: null,
        email: null,
      },
    };
  },
  methods: {
    clearForm() {
      this.usuario = {
        nome: null,
        sobrenome: null,
        email: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  background-color: $bahama-blue;
  color: $white;
}
</style>
