<template>
  <div class="p-3 h-100">
    <b-form
      @submit.prevent="$emit('filtered', armazenamento)"
      class="w-100 h-100 d-flex flex-column justify-content-between"
    >
      <div>
        <label class="mb-0 font-weight-bold" label-for="descricao"
          >Descrição</label
        >
        <b-input id="descricao" v-model="armazenamento.descricao" type="text" />
        <div class="mt-2">
          <label class="mb-0 font-weight-bold" label-for="autorizado_ea">Autorizado para Entreposto Aduaneiro</label>
            <select id="autorizado_ea" v-model="armazenamento.autorizado_ea">
              <option disabled value=" ">Escolha um item</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
              <option value="all">Todos</option>
            </select>
            <!---
            <b-form-checkbox
              id="autorizado_ea"
              v-model="armazenamento.autorizado_ea"
              name="autorizado_ea"
              value="true"
              unchecked-value="false"
            >
            Autorizado para Entreposto Aduaneiro
            (Apenas Sim?)
            </b-form-checkbox>
            --->
          </div>
      </div>
      <div class="pt-4 w-100 d-flex justify-content-center">
        <b-button class="custom-button w-100" type="submit"> Filtrar </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoadingRoles: false,
      armazenamento: {
        descricao: null,
        autorizado_ea: null,
      },
    };
  },
  methods: {
    clearForm() {
      this.armazenamento = {
        descricao: null,
        autorizado_ea: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-button {
  background-color: $bahama-blue;
  color: $white;
}
</style>
