<template>
  <div id="app" class="fix-grid d-grid h-100">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.fix-grid {
  grid-template-rows: 1fr;
}
</style>
