export const oidcSettings = {
  authority: window.$environment.OIDC_SERVER_URL,
  adminAuthority: window.$environment.OIDC_SERVER_URL,
  clientId: 'docas-api',
  responseType: 'code',
  redirectUri: `${window.location.origin}/oidc-callback`,
  popupRedirectUri: `${window.location.origin}/oidc-popup-callback`,
  scope: 'openid email roles offline_access',
  automaticSilentRenew: true,
  automaticSilentSignin: false,
  silentRedirectUri: `${window.location.origin}/silent-renew-oidc.html`,
};
