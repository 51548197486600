import { CustomAxios } from './axios';

export const MovimentacaoService = {
  criar: ({ empresa, dta, containers, due, declaracaoImportacao, armazenamento }) =>
    CustomAxios.post('movimentacoes', {
      empresa,
      dta,
      containers,
      due,
      declaracaoImportacao,
      armazenamento
    }),
  excluirPorId: ({ movimentacaoId }) => CustomAxios.delete(`movimentacoes/${movimentacaoId}`),
  getMovimentacoes: ({ currentPage, pageSize, sortBy, orderBy, empresa, dta }) => {
    let url = `movimentacoes?page=${currentPage - 1}&size=${pageSize}`;
    if (sortBy && orderBy) url += `&sort=${sortBy},${orderBy}`;
    if (empresa) url += `&empresa=${empresa}`;
    if (dta) url += `&dta=${dta}`;
    return CustomAxios.get(url);
  },
  buscarPorId: ({ movimentacaoId }) => CustomAxios.get(`movimentacoes/buscar-por-id/${movimentacaoId}`),
  atualizar: ({ movimentacaoId, empresa, dta, containers, due, declaracaoImportacao, armazenamento }) =>
    CustomAxios.put(`movimentacoes/${movimentacaoId}`, {
      empresa,
      dta,
      containers,
      due,
      declaracaoImportacao,
      armazenamento
    }),
};
