import { CustomAxios } from './axios';

export const OperadorPortuarioService = {
  criar: ({ razaoSocial, cnpj }) =>
    CustomAxios.post('operadores-portuarios', {
      razaoSocial,
      cnpj
    }),
  excluirPorId: ({ operadorPortuarioId }) => CustomAxios.delete(`operadores-portuarios/${operadorPortuarioId}`),
  getEmpresas: ({ currentPage, pageSize, sortBy, orderBy, razaoSocial, cnpj }) => {
    let url = `operadores-portuarios?page=${currentPage - 1}&size=${pageSize}`;
    if (sortBy && orderBy) url += `&sort=${sortBy},${orderBy}`;
    if (razaoSocial) url += `&razaoSocial=${razaoSocial}`;
    if (cnpj) url += `&cnpj=${cnpj}`;

    return CustomAxios.get(url);
  },
  buscarPorId: ({ operadorPortuarioId }) => CustomAxios.get(`operadores-portuarios/buscar-por-id/${operadorPortuarioId}`),
  atualizar: ({ operadorPortuarioId, razaoSocial, cnpj }) =>
    CustomAxios.put(`operadores-portuarios/${operadorPortuarioId}`, {
      razaoSocial,
      cnpj
    }),
};
